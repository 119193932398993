$(document).ready(function() {
	let pgurl = window.location.href.split('/').pop();
	// let pgurl = window.location.href,
	// 	pgurl2 = window.location.href.split('/').pop(),
	// 	hostname = window.location.hostname,
	// 	protocol = window.location.protocol;

	if (pgurl=="")
		$('.js-nav #index').addClass('active');
	$(".js-nav .link").each(function(){
		//let href = protocol + "//" + hostname + $(this).attr("href");
		let href = $(this).attr("href");
		if(href == pgurl)
			$(this).addClass("active");
	});

	if ($('.js-sliderMain').length) {
		$('.js-sliderMain').slick({
			infinite: true,
			arrows: false,
			dots: true,
			speed: 1000,
			autoplay: true,
			autoplaySpeed: 8000,
			fade: true
		})
	}
	if ($('.js-sliderPartner').length) {
		$('.js-sliderPartner').slick({
			infinite: true,
			speed: 800,
			autoplay: true,
			autoplaySpeed: 10000,
			infinite: true,
			slidesToShow: 5,
			slidesToScroll: 5,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 4,
						slidesToScroll: 4
					}
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 3
					}
				}
			]
		})
	}

	if ($('.js-magnificPopup').length) {
		$('.js-magnificPopup').each(function() {
			$(this).magnificPopup({
				delegate: 'a:not(.slick-cloned)',
				type:'image',
				gallery: {
					enabled: true
				}
			});
		})
	}

	$('.js-navTrigger').on('click', function() {
		var target = $('.js-nav'),
			body = $('body');

		target.addClass('opened');
		body.addClass('nav-opened');
	});

	$('.js-asideTrigger').on('click', function() {
		var aside = $('.js-aside'),
			body = $('body');

		aside.addClass('opened');
		body.addClass('nav-opened');
	});

	//SMOOTSCROLL
	$('.js-smoothScroll').on('click', function() {
		let elem = $(this),
			header = $('.header');
		smoothScroll(elem, header);
	});

	// TAB NAVIGATION
	$('.js-tabLink').on('click', function() {
		var target = $(this).data('target'),
			parent = $(this).closest('.js-tabParent'),
			text= $(this).text();

		parent.find('.js-tabNav .js-tabLink').removeClass('active');
		parent.find('.js-tabContent .tab-panel').removeClass('active');
		parent.find('.js-tabNav .js-tabCur').text(text);
		$(target).addClass('active');
		$(this).addClass('active');
	});

	if(('.js-quantity').length){
		$('.js-quantityTrigger').click(function(){
			var parent = $(this).closest('.js-quantity'),
				button = $(this),
				value = parent.find('.js-quantityVal')
			quantityTrigger(button, value);
		});

		$(".js-quantityVal").on("keyup", function() {
			var value = $(this)
			quantityVal(value);
	    });
	}
});
