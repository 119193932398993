$(document).on('mousedown touchstart', function(e){
	let blur_off = $('.js-blurOff');
	if(!blur_off.is(e.target) && blur_off.has(e.target).length === 0){
		$('.js-nav').removeClass('opened');
		$('.js-filterCollapse').removeClass('opened');
		$('.js-aside').removeClass('opened');
		$('body').removeClass('nav-opened');
	}
	// let filterContent = $('.js-filterContent');
	// if (!filterContent.is(e.target) && filterContent.has(e.target).length === 0) {
	// 	$('.js-filterCollapse .collapse').collapse('hide');
	// }

	if (!$(e.target).is('.js-filterContent *')) {
		$('.js-filterCollapse .collapse').collapse('hide');
	}
});
