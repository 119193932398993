$(document).on('scroll', function(e){
    let scrollTop = $(this).scrollTop(),
        win_h = $(window).height(),
        header = $('.header');

    $('section').each(function() {
        let pos = $(this).offset().top;
        if(scrollTop >= (pos - (win_h / 2))) {
            $(this).addClass('pageScroll');
        }
    });
    
    (scrollTop >= (win_h / 3)) ? header.addClass('sticky') : header.removeClass('sticky');
});
